$(document).ready(function () {

    if (localStorage.usuarioSaiu == true) {
        localStorage.usuarioSaiu = undefined;
    }
    if (typeof sessionTimeoutZator != "undefined") {

        // Propriedades extras para o controlador de tempo de sessão:
        sessionTimeoutZator.modalInativo = $("#md-session-timeout");
        sessionTimeoutZator.spanTempoInativo = $("#md-session-timeout span#sessionTimeoutTime");

        sessionTimeoutZator.init({
            timeToIdle:1 * 60  * 60 * 1000, // 1 hora para ser considerado inativo
            timeToKick:1.5 * 60 * 1000, // 1 minuto e meio para finalizar a sessão (kick)
            verbose:false,
            onEnterIdle : function() {
                this.modalInativo.modal('show');
            },
            onLeaveIdle : function() {
                this.modalInativo.modal('hide');
            },
            onKick : function() {
                if (location.pathname != '/sessaoExpirada'
                 && location.pathname != '/sair') {
                    location.replace("/sessaoExpirada");
                 }
            },
            onUpdate : function(data) {
                if (typeof localStorage != "undefined") {
                    if (localStorage.usuarioSaiu == true) {
                        this.onKick();
                    }
                }

                var dataSessaoExpira = data.startTime + this.timeToIdle + this.timeToKick;
                var tempoParaExpirarSessao = dataSessaoExpira - data.currentTime;
                if(this.verbose) console.log(tempoParaExpirarSessao);

                if (data.currentState == "idle") {

                    var jsDateTempoParaExpirarSessao = new Date(tempoParaExpirarSessao);

                    var tempoParaExpirarSessao = {
                      min:jsDateTempoParaExpirarSessao.getMinutes()+"",
                      sec:jsDateTempoParaExpirarSessao.getSeconds()+""
                    }

                    if (tempoParaExpirarSessao.min.length < 2) {
                       tempoParaExpirarSessao.min = "0"+tempoParaExpirarSessao.min;
                    }
                    if (tempoParaExpirarSessao.sec.length < 2) {
                       tempoParaExpirarSessao.sec = "0"+tempoParaExpirarSessao.sec;
                    }

                    strTempoParaExpirarSessao = tempoParaExpirarSessao.min + ":" + tempoParaExpirarSessao.sec;

                    this.spanTempoInativo.text(strTempoParaExpirarSessao);
                }
            }
        })
    }



    /*
     * Função que joga o WHOOPS do laravel na tela - feita para substituir "console.error(erro)"
     * e assim capturar erros mais facilmente
     */
    window.showErrorWhoops = function(erro) {
        var newDoc = document.open("text/html", "replace");
        newDoc.write(erro);
        newDoc.close();
    }

    $(".ajuda-popover").popover({trigger: 'hover'});

    $('[data-toggle="tooltip"]').bstooltip();

    /******* Modificando o alerta padrão do navegador *******/
    (function(proxied) {
        window.alert = function(message, title, bgColor, btnClass) {
            if (bgColor == null) bgColor = "#d9534f";
            if (btnClass == null) btnClass = "primary";
            var title = (title) ? title : 'Atenção';
            $('#md-alert .modal-header').css('background-color',bgColor);
            $('#md-alert .modal-title span').html(title);
            $('#md-alert .modal-body ').html(message);
            $('#md-alert').modal('show');
            btnClass.replace("btn-","");
            $("#md-alert .btn").removeClass().addClass('btn btn-'+btnClass);
            //return proxied.apply(this, arguments);
        };
    })(window.alert);

    /******* Modificando o Confirm padrão do navegador *******/
    (function(proxied) {
        window.confirm = function(message, callback, title, buttonYes, ButtonNo, callbackArgument) {
            var title = (title) ? title : 'Confirme';
            var buttonYes = (buttonYes) ? buttonYes : 'Sim';
            var ButtonNo = (ButtonNo) ? ButtonNo : 'Cancelar';

            $('#md-confirm .modal-title span').html(title);
            $('#md-confirm .btn-confirm').html(buttonYes);
            $('#md-confirm .btn-cancelar').html(ButtonNo);
            $('#md-confirm .modal-body ').html(message);
            $('#md-confirm').modal('show');

            $('#md-confirm .btn-confirm').unbind().click(function() {
                callback(callbackArgument);
            });
            //return proxied.apply(this, arguments);
        };
    })(window.alert);

    /******* Incluindo uma nova funcionalidade para os Arrays *******/
    if (![].contains) {
        Object.defineProperty(Array.prototype, 'contains', {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (searchElement/*, fromIndex*/) {
                if (this === undefined || this === null) {
                    throw new TypeError('Cannot convert this value to object');
                }
                var O = Object(this);
                var len = parseInt(O.length) || 0;
                if (len === 0) {
                    return false;
                }
                var n = parseInt(arguments[1]) || 0;
                if (n >= len) {
                    return false;
                }
                var k;
                if (n >= 0) {
                    k = n;
                } else {
                    k = len + n;
                    if (k < 0) k = 0;
                }
                while (k < len) {
                    var currentElement = O[k];
                    if (searchElement === currentElement ||
                        searchElement !== searchElement && currentElement !== currentElement
                    ) {
                        return true;
                    }
                    k++;
                }
                return false;
            }
        });
    }

    Array.prototype.unique = function() {
        var arr = [];
        for(var i = 0; i < this.length; i++) {
            if(!arr.contains(this[i])) {
                arr.push(this[i]);
            }
        }
        return arr;
    }


});

var dependenciasInjetar = [
    'moduloGlobal',
    'ngAnimate',
];


var moduloGlobal = angular.module("moduloGlobal",[]);

// so telas utilizando sistema.blade puro
var app = angular.module('app', dependenciasInjetar, function($controllerProvider, $compileProvider, $provide) {
    angular.providers = {
        $controllerProvider: $controllerProvider,
        $compileProvider: $compileProvider,
        $provide: $provide
    };
});
function fnAppGeralController($scope, $http) {

}

fnAppGeralController.$inject = ['$scope', '$http'];
app.controller('baseController', fnAppGeralController);


$('#fullscreen_btn').on('click', function() {
    if (screenfull.enabled) {
        screenfull.toggle();
    }

    if (screenfull.isFullscreen){

      $("#header").animate({
          'margin-top': '-80px',
          opacity: '0.1'
      }, function() {
        $("#header").addClass('fullscreen');
        $("main").children().first().animate({
            'margin-top': '-80px'
        });
      });
    }else{

      $("#header").removeClass('fullscreen');
      $("#header").animate({
          'margin-top': '0px',
          opacity: '1.0'
      }, function () {
        $("main").children().first().animate({
            'margin-top': '0px'
        });
      });
    }
});

$( "#header" ).hover(
  function() {
    if ($(this).hasClass('fullscreen')){

      $("#header").animate({
          'margin-top': '0px',
          opacity: '1.0'
      });
    }
  }, function() {
    if ($(this).hasClass('fullscreen')){

      $("#header").animate({
          'margin-top': '-80px',
          opacity: '0.1'
      });
    }
  }
);

$(window).resize(function(){
     var hasVScroll = document.body.scrollHeight > document.body.clientHeight,
         altura = $(window).height(),
         largura = (hasVScroll ? $(window).width() + 17 : $(window).width());


     if (screen.height == altura && screen.width == largura){

       $("#header").animate({
           'margin-top': '-80px',
           opacity: '0.1'
       }, function() {
         $("#header").addClass('fullscreen');
         $("main").children().first().animate({
             'margin-top': '-80px'
         });
       });
     }else{

       $("#header").removeClass('fullscreen');
       $("#header").animate({
           'margin-top': '0px',
           opacity: '1.0'
       }, function () {
         $("main").children().first().animate({
             'margin-top': '0px'
         });
       });
     }


});

(function($) {
    $.fn.hasScrollBar = function() {
        return this.get(0).scrollHeight > this.height();
    }
})(jQuery);
String.prototype.toSlug = function(){
    var st = this.toLowerCase();
    st = st.replace(/[\u00C0-\u00C5]/ig,'a')
    st = st.replace(/[\u00C8-\u00CB]/ig,'e')
    st = st.replace(/[\u00CC-\u00CF]/ig,'i')
    st = st.replace(/[\u00D2-\u00D6]/ig,'o')
    st = st.replace(/[\u00D9-\u00DC]/ig,'u')
    st = st.replace(/[\u00D1]/ig,'n')
    st = st.replace(/[^a-z0-9 ]+/gi,'')
    st = st.trim().replace(/ /g,'-');
    st = st.replace(/[\-]{2}/g,'');
    return (st.replace(/[^a-z0-9\- ]*/gi,''));
}