
function makeEntidadeService(_module) {

	_module.service("entidadeService", function() {

			this.populaComboLojas = function(lista){
		        var nivelCont = 0,
		        aux = "";
		        
		        angular.forEach(lista, function (loja) {
		                
		                if( loja.id.substring(0,3) == "niv"){
		                    if(nivelCont>0){
		                        aux += '</optgroup>';
		                    }
		                    aux += "<optgroup label='" + loja.title + "'>";    
		                }
		                if(loja.id.substring(0,3) == "ent"){
		                    aux += '<option value=' + loja.id.substr(9) + '>' + loja.title + '</option>';
		                }
		                nivelCont=nivelCont+1;
		                if(nivelCont == lista.length){
		                    aux += '</optgroup ultimo>';
		                    
		                }                
		            });
		        return aux;
	            // $(queryElemento).append(aux).change();
	            // $(queryElemento).selectpicker('refresh');
		    }

		}
	)


}
