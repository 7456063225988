(function() {
    var days = ['domingo','segunda-feira','terça-feira','quarta-feira','quinta-feira','sexta-feira','sabado'];
    var months = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
    var genre = ['o', 'a', 'a', 'a', 'a', 'a', 'o'];
    var ordinal = ['primeir', 'segund', 'terceir', 'quart', 'quint'];

    Date.prototype.getMonthName = function() {
        return months[ this.getMonth() ];
    };
    Date.prototype.getDayName = function(artigo) {
        return ((artigo === undefined) ? days[ this.getDay() ] : 'n' + genre[ this.getDay() ] + ' ' + days[ this.getDay() ]);
    };

    Date.prototype.getWeekDay = function() {
        return this.getDay()+1;
    };

    Date.prototype.setByWeekAndDay = function(week, day) {
        var y = this.getFullYear(),
            m = this.getMonth(),
            first = new Date(y, m, 1).getDay(),
            d = (((week - 1) * 7) + day) - first,
            aux = new Date(y, m, d);

        return aux;
    };

    Date.prototype.setByWeekDay = function(day) {
        var y = this.getFullYear(),
            m = this.getMonth(),
            first = new Date(y, m, 1).getDay(),
            d = (7 + day) - first,
            aux = new Date(y, m, d);

        return aux;
    };

    Date.prototype.setByMonthDay = function(day) {
        var y = this.getFullYear(),
            m = this.getMonth(),
            last = new Date(y, m + 1, 0).getDate(),
            d = (day > last ? last : day),
            aux = new Date(y, m, d);

        return aux;
    };

    Date.prototype.setByWorkDay = function(days) {
        var y = this.getFullYear(),
            m = this.getMonth(),
            s_date = new Date(y, m, 1),
            newDate = new Date(s_date.valueOf()),
            businessDaysLeft,
            isWeekend,
            direction;

        if (days !== parseInt(days, 10)) {
            throw new TypeError('Esta função só pode ser usada em dias inteiros.');
        }

        if (days === 0) {
            return s_date;
        }
        direction = days > 0 ? 1 : -1;

        businessDaysLeft = Math.abs(days);
        while (businessDaysLeft) {
            newDate.setDate(newDate.getDate() + direction);
            isWeekend = newDate.getDay() in {0: 'Domingo', 6: 'Sabado'};
            if (!isWeekend) {
                businessDaysLeft--;
            }
        }
        return newDate;
    };

    Date.prototype.getNumberWeek = function(ret_value) {
        var floor = Math.floor(this.getDate() / 7),
            d_sem = this.getDay();


        if (ret_value === undefined) {
            if (this.getDate() % 7 == 0) return 'n' + genre[d_sem] + ' ' + ordinal[floor-1] + genre[d_sem];
            return 'n' + genre[d_sem] + ' ' + ordinal[floor] + genre[d_sem];
        } else {
            if (this.getDate() % 7 == 0) return floor-1;
            return floor;
        }

    };

    Date.prototype.getWorkDaysNum = function() {
        var y = this.getFullYear(),
            m = this.getMonth(),
            d = this.getDate(),
            data_i = new Date(y, m, 1),
            data_f = new Date(y, m, d),
            millisecondsPerDay = 86400 * 1000;

        if (data_f < data_i)
            return 0;

        data_i.setHours(0,0,0,1);
        data_f.setHours(23,59,59,999);
        var diff = data_f - data_i,
            days = Math.ceil(diff / millisecondsPerDay),
            weeks = Math.floor(days / 7);

        days = days - (weeks * 2);
        var startDay = data_i.getDay(),
            endDay = data_f.getDay();

        if (startDay - endDay > 1)
            days -= 2;

        if ((startDay == 0 && endDay != 6) || (endDay == 6 && startDay != 0))
            days--;

        return (days == 0 ? 1 : days);
    };

    Date.prototype.getWorkDays = function() {
        var data = this.getWorkDaysNum(),
            data_aux = new Date((this.getYear() + 1900), this.getMonth() + 1, 0 ).getWorkDaysNum();

        if (data == data_aux) return 'último';
        return data + 'º';
    };
})();